import React from 'react'
import { graphql } from 'gatsby'
import CommonFooter from '../components/footer/commonFooter'
import SiteNavigation from '../components/header/siteNavigation'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'
import AssemblyForm from '../components/forms/assemblyForm'
import CardWithIcon from '../components/common/cardWithIcon'

const SchedeElettroniche = ({ data }) => {
  const { t } = useTranslation();
  return (
  <>
    <Seo
      title={t('schede.seoTitle')}
      description={t('schede.seoDescription')}
      seoImage={data.seoImage.childImageSharp.resize.src}
    />
    <SiteNavigation />
    <div className='maingrad'>
      <div className="md:max-w-5xl mx-auto pt-12 md:pt-48">
        <h1 className='text-3xl md:text-5xl font-semibold text-center py-4 text-white'>{t('schede.titleMain')}</h1>
        <h2 className='text-xl md:text-2xl font-semibold text-center py-4 text-white'>{t('schede.title1')} <span className="bg-gray-50 rounded-full px-4 text-gray-700">{t('schede.title2')}</span> {t('schede.title3')}</h2>
      </div>
      <div className="md:max-w-7xl py-12 mx-auto">  
        <div className='md:flex'>
          <div className='bg-white md:rounded-l-3xl md:w-1/2 py-6 md:py-12 px-8 md:px-24'>
              <CardWithIcon
                title={t('schede.cardTitle')}
                description={t('schede.cardDesc')}
              />
          </div>
          <div className='bg-gray-100 p-12 md:rounded-r-3xl md:w-1/2'>
            <AssemblyForm />
          </div>
        </div>
      </div>
    </div>
    <div className='py-8 md:py-16'>
      <div className="max-w-7xl mx-auto grid grid-cols-1 px-4 md:px-0 md:grid-cols-2 gap-8">
        <div className='bg-gray-50 rounded-3xl py-8 px-12'>
          <h2 className='font-semibold text-xl mb-4'>{t('schede.info1Title')}</h2>
          <p>{t('schede.info1Desc')}</p>
        </div>
        <div className='bg-gray-50 rounded-3xl py-8 px-12'>
          <h2 className='font-semibold text-xl mb-4'>{t('schede.info2Title')}</h2>
          <p>{t('schede.info2Desc')}</p>
        </div>
        <div className='bg-gray-50 rounded-3xl py-8 px-12'>
          <h2 className='font-semibold text-xl mb-4'>{t('schede.info3Title')}</h2>
          <p>{t('schede.info3Desc')}</p>
        </div>
        <div className='bg-gray-50 rounded-3xl py-8 px-12'>
          <h2 className='font-semibold text-xl mb-4'>{t('schede.info4Title')}</h2>
          <p>{t('schede.info4Desc')}</p>
        </div>
      </div>
    </div>
    <CommonFooter />
  </>
)
}

export default SchedeElettroniche

export const schedeQuery = graphql`
  query {
    seoImage: file(
      relativePath: { eq: "manufacturing/SMT_Assembly_PCBA.jpg" }
    ) {
      childImageSharp {
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
