import React from 'react'

const CardWithIcon = props => (
  <div className='bg-gray-50 rounded-2xl px-8 py-8'>
    <div className='flex space-x-8'>
        <svg xmlns="http://www.w3.org/2000/svg" className="flex-none h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
        </svg>
        <div>
            <p className='font-semibold mb-4'>{props.title}</p>
            <p>{props.description}</p>
        </div>
    </div>
  </div>
)

export default CardWithIcon
