import React from 'react'
import Link from '../../utilities/link'
import { withTranslation } from 'react-i18next'

class AssemblyForm extends React.Component {
  render() {
    const { t } = this.props
    let lang =
      typeof window !== `undefined`
        ? window.location.pathname.split('/')[1]
        : this.props.i18n.language.split('-')[0]

    return (
      <form
        method="POST"
        name="assemblaggio"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="assemblaggio" />
        <input type="hidden" name="bot-field" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-4">
            <div>
              <p className="font-semibold">
                {t('projectFormFields.firstName')}
              </p>
              <input className="rounded-full w-full h-10 px-6" type="text" name="firstname"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('projectFormFields.lastName')}
              </p>
              <input className="rounded-full w-full h-10 px-6" type="text" name="lastname"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('projectFormFields.role')}
              </p>
              <input className="rounded-full w-full h-10 px-6" type="text" name="role"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('projectFormFields.companyName')}
              </p>
              <input className="rounded-full w-full h-10 px-6" type="text" name="companyname"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('projectFormFields.phone')}
              </p>
              <input className="rounded-full w-full h-10 px-6" type="text" name="phoneNumber"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('projectFormFields.email')}
              </p>
              <input className="rounded-full w-full h-10 px-6" type="email" name="email"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('schede.diba')}
              </p>
              <input className="bg-white rounded-full block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-gray-50 file:text-gray-700
        hover:file:bg-green-200" type="file" name="diba"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('schede.gerber')}
              </p>
              <input className="bg-white rounded-full block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-gray-50 file:text-gray-700
        hover:file:bg-green-100" type="file" name="gerber"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('schede.qty')}
              </p>
              <input className="rounded-full w-full h-10 px-6" type="text" name="qty"></input>
            </div>
            <div>
              <p className="font-semibold">
                {t('projectFormFields.message')}
              </p>
              <textarea className="rounded w-full h-10 px-6" type="textarea" rows="2" name="message"></textarea>
            </div>
        </div>
        <label className="mt-6 flex items-center justify-center space-x-2 text-sm font-medium text-slate-600">
          <input type="checkbox" className="accent-blue-500"/>
          <span>{t('projectFormFields.privacy')}{' '}
              <Link to={'/privacy'} style={{ color: '#444' }}>
                Privacy Policy
              </Link></span>
        </label>

          <button type="submit" className="mt-8 w-full maingrad hover:text-gray-700 rounded-full px-8 text-white font-semibold text-center py-2">
            {t('projectFormFields.send')}
          </button>
 
      </form>
    )
  }
}

export default withTranslation()(AssemblyForm)
